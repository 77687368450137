import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { isValidEmail } from '../utils/validation';

import Button from './Button';
import Fade from './Fade';
import FadeEntry from './FadeEntry';

const ContactForm = () => {

	let [email, setEmail] = useState('');
	let [message, setMessage] = useState('');
	let [sending, setSending] = useState(false);
	let [sent, setSent] = useState(false);
	let [alert, setAlert] = useState(null);
	let [showAlert, setShowAlert] = useState(null);

	useEffect(() => {
		if(!sent) return;

		setEmail('');
		setMessage('');
	}, [sent]);

	useEffect(() => {
		if(showAlert) {
			setShowAlert(false);
		}
	}, [email, message]);

	return(
		<FadeEntry
			className={'form-container'}
		>
			<Form
				onSubmit={async e => {
					e.preventDefault();

					if(!email || !message) return null;

					setSending(true);

					let response = await sendForm(email, message);

					setSending(false);
					setSent(response);
					setAlert({
						message: response ? 'Your message was sent.' : 'There was a problem. Please contact directly.',
						type: response ? 'success' : 'error',
					});
					setShowAlert(true);
				}}
			>
				<input
					type="email"
					value={email}
					onChange={({ target }) => setEmail(target.value)}
					required
					placeholder={'Email'}
				/>
				<textarea
					value={message}
					onChange={({ target }) => setMessage(target.value)}
					required
					placeholder={'Message'}
				/>

				<div className="form__bottom">
					<Fade
						active={showAlert}
					>
						<Alert
							type={alert && alert.type}
						>{ alert ? alert.message : '&nbsp;' }</Alert>
					</Fade>

					<Button
						disabled={sending || !message || !isValidEmail(email)}
						size={'small'}
					>Send</Button>
				</div>
			</Form>
		</FadeEntry>
	);
}

const encode = data =>
	Object.keys(data)
		.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
		.join('&');

const sendForm = async (email, message) => {
	try {
		await fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": "contact",
				"email": email,
				"message": message,
			})
		})

		return true;
	} catch(error) {
		console.error(error);

		return false;
	}
}

const Form = styled.form`
	input,
	textarea {
		width: 100%;
	}
	
	textarea {
		height: 220px;
	}
	
	${Button} {
		display: flex;
		margin-left: auto;
	}

	>* {
		+* {
			margin-top: 20px;
		}
	}
	
	.form__bottom {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		//margin-top: 14px;
	}
`;

const Alert = styled.p`
	display: flex;
	align-items: center;
	padding: 14px 18px;
	border: 1px solid ${({ theme, type }) => type === 'success' ? theme.color.success : theme.color.danger};
`;

export default ContactForm;
