import { Link } from 'gatsby';

export const isValidEmail = email => {
	let regex = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g)


	return regex.test(email);
}

export const getLinkProps = (link) => {
	const linkProps = {}
	const isContent = link && link.slice(0, 11) === '/wp-content';

	let isExt = link && !['https://wri-group', 'https://www.wri-group'].find(url => link.includes(url));

	if(isContent) {
		isExt = true;
	} else if(link && link.charAt(0) === '/') {
		isExt = false;
	}

	if(isContent) {
		link = 'https://admin.wri-group.com' + link;
	}

	if(isExt) {
		linkProps.href = link;
		linkProps.target = '_blank';
		linkProps.as = 'a';
	} else {
		linkProps.to = link ? link.replace(/https:\/\/(www\.|)wri-group(\.co\.uk|\.com)/g, '') : '';
		linkProps.as = Link;
	}

	return linkProps;
}