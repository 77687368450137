import React, { useState, useEffect } from 'react';
import Transition from 'react-transition-group/Transition';
import styled from 'styled-components';

const Fade =  React.forwardRef(({ active, children, style, delay, duration, className, appear, as, mountOnEnter, unmountOnExit }, ref) => {

	const defaultStyle = {
		transition: `opacity ${duration}ms ease-in-out`,
		transitionDelay: `${delay}ms`,
		opacity: 0,
	}

	const transitionStyles = {
		entering: {
			opacity: 0,
		},
		entered: {
			opacity: 1,
		},
		exiting: {
			opacity: 1
		},
		exited: {
			opacity: 0,
		}
	};

	let timeout = duration + delay;

	return(
		<Transition
			in={active}
			timeout={timeout}
			unmountOnExit={unmountOnExit}
			mountOnEnter={mountOnEnter}
			appear={appear}
		>
			{(state) => {
				const styles = {
					...style,
					...defaultStyle,
					...transitionStyles[state]
				}

				return(
					<El
						style={styles}
						className={className}
						ref={ref}
						as={as}
					>
						{ children }
					</El>
				)
			}}
		</Transition>
	);
});

const El = styled.div``;

Fade.defaultProps = {
	duration: 400,
	delay: 0,
	mountOnEnter: false,
	unmountOnExit: false,
	appear: true,
}

export default Fade;
