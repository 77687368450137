import React, { useState, useRef } from 'react';
import { useInView } from 'react-hook-inview';

import Fade from './Fade';

const FadeEntry = ({ children, ...props }) => {
	const $ref = useRef();
	const [visible, setVisible] = useState(false);

	useInView({
		target: $ref,
		threshold: 0,
		onEnter: (entry) => setVisible(entry.isIntersecting),
		// onLeave: (entry) => setVisible(entry.isIntersecting),
	});

	return(
		<Fade
			ref={$ref}
			active={visible}
			{...props}
		>
			{ children }
		</Fade>
	);
}

export default FadeEntry;