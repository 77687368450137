import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import FadeEntry from './FadeEntry';

const MapContainer = ({ coords, google }) => {

	if(!coords) return null;

	return(
		<FadeEntry>
			<Map
				google={google}
				zoom={15}
				initialCenter={{
					lat: parseFloat(coords.lat),
					lng: parseFloat(coords.lng),
				}}
				styles={[
					{
						"featureType": "all",
						"stylers": [
							{
								"saturation": 0
							},
							{
								"hue": "#e7ecf0"
							}
						]
					},
					{
						"featureType": "road",
						"stylers": [
							{
								"saturation": -70
							}
						]
					},
					{
						"featureType": "transit",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "poi",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "water",
						"stylers": [
							{
								"visibility": "simplified"
							},
							{
								"saturation": -60
							}
						]
					}
				]}
			>
				<Marker
					position={{
						lat: parseFloat(coords.lat),
						lng: parseFloat(coords.lng),
					}}
				/>
			</Map>
		</FadeEntry>
	);
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyA8gQvc8yVR4qajYPG_LvtsKbJ49T0zbsA'
})(MapContainer);