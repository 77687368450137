import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import FadeEntry from './FadeEntry';
import Title from './Title';

const Hero = ({ title, titleSize, titleStyles, text, image, imageMobile, logo, centered, tall, white }) => {
	let img = image ? image.sizes ? image.sizes.large.source_url : image.source_url : '',
		imgMobile = imageMobile ? imageMobile.sizes ? imageMobile.sizes.large.source_url : imageMobile.source_url : null;

	return(
		<Outer
			image={img}
			imageMobile={imgMobile}
			centered={centered}
			tall={tall}
			white={white}
		>
			<Container>
				{ logo ? (
					<FadeEntry>
						<img
							src={logo.source_url}
							alt={logo.alt_text}
							className={'hero__logo'}
						/>
					</FadeEntry>
				) : null }

				{ title ? (
					<FadeEntry>
						<Title
							size={titleSize ? titleSize : centered ? 'titleXxl' : 'titleLg'}
							white={!white}
							css={titleStyles}
						>{ title }</Title>
					</FadeEntry>
				) : null }

				{ text ? (
					<FadeEntry>
						<p dangerouslySetInnerHTML={{ __html: text }} />
					</FadeEntry>
				) : null }
			</Container>
		</Outer>
	);
}

const Outer = styled.div`
	text-align: ${({ centered }) => centered ? 'center' : 'left'};
	background-color: ${({ theme, white }) => white ? '#fff' : theme.color.blue};
	background-image: ${({ image }) => image ? `url(${image})` : 'none'};
	background-position: ${({ centered }) => centered ? 'center' : 'center right'};;
	background-size: ${({ centered }) => centered ? '100%' : 'auto 80%'};;
	background-repeat: no-repeat;
	color: ${({ theme, white }) => white ? theme.color.blue : theme.color.white};
	
	${Container} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: ${({ centered }) => centered ? 'center' : 'flex-start'};
		height: ${({ tall }) => tall ? '668px' : '500px'};
		
		>* {
			max-width: ${({ centered }) => centered ? '750px' : '550px'};
		}
	}
	
	p {
		margin-top: 22px;
	}
	
	.hero__logo {
		max-width: 240px;
		margin-bottom: 35px;
	}
	
	@media (max-width: 1199px) {
		background-size: ${({ centered }) => centered ? 'cover' : 'auto 60%'};
	}
	
	@media (max-width: 991px) {
		background-image: ${({ image, imageMobile, centered }) => imageMobile ? `url(${imageMobile})` : centered ? `url(${image})` : 'none'};
		background-position: ${({ centered }) => centered ? 'center' : 'top right'};
			
	}
	
	@media (max-width: 599px) {
		.hero__logo {
			max-width: 100px;
			margin-bottom: 25px;
		}
		
		${Container} {
			height: 340px;
		
			>* {
				max-width: ${({ centered }) => centered ? '500px' : '550px'};
			}
		}
		
		${({ centered }) => centered ? `
			${Title} {
				font-size: 32px;
			}
		` : `
			${Container} {
				justify-content: flex-end;
				padding-bottom: 60px;
			}
		`}
	}
`;

export default Hero;