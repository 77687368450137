import React, { Fragment }  from 'react';
import { graphql } from 'gatsby';

import ContactBlock from '../components/ContactBlock';
import Hero from '../components/Hero';
import MetaData from '../components/MetaData';

const Services = ({ data }) => {

	let {
		wordpressPage: {
			title,
			acf: {
				seo_title,
				seo_description,
				seo_images,
				hero,
				content_title,
			}
		},
		wordpressAcfOptions: {
			options: {
				coordinates
			}
		}
	} = data;

	return(
		<Fragment>
			<MetaData
				title={seo_title || title}
				desciption={seo_description}
				images={seo_images}
			/>

			<Hero
				title={hero && hero.hero_title}
				text={hero && hero.hero_text}
				image={hero && hero.hero_image}
				imageMobile={hero && hero.hero_image_mobile}
			/>

			<ContactBlock
				title={content_title}
				coordinates={coordinates}
			/>
		</Fragment>
	);
}

export const pageQuery = graphql`
    query {
		wordpressPage(slug: {eq: "enquiries"}) {
			title
			acf {
				seo_title
				seo_description
				seo_images {
					image {
					source_url
					}
				}
				content_title
				hero {
					hero_title
					hero_text
					hero_image {
						sizes {
							large {
								source_url
								alt_text
							}
						}
					}
					hero_image_mobile {
						sizes {
							large {
								source_url
								alt_text
							}
						}
					}
				}
			}
		}
		wordpressAcfOptions {
			options {
				coordinates {
					lat
					lng
				}
			}
		}
	}
`;

export default Services;