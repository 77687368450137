import React from 'react';
import styled from 'styled-components';

import ContactForm from './ContactForm';
import Container from './Container';
import FadeEntry from './FadeEntry';
import Map from './Map';
import Title from './Title';

const ContactBlock = ({ title, coordinates }) => {
	return(
		<Outer>
			<Container>
				{ title ? (
					<FadeEntry
						className={'contact-block__title'}
					>
						<Title
							as={'h2'}
						>{ title }</Title>
					</FadeEntry>
				) : null }

				<Inner>
					<div className="map-container">
						<Map
							coords={coordinates}
						/>
					</div>

					<ContactForm />
				</Inner>
			</Container>
		</Outer>
	);
}

const Inner = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Outer = styled.div`
	padding: 60px 0 100px;
	
	.map-container {
		position: relative;
		width: calc(50% - 15px);
		height: 380px;
	}
	
	.form-container {
		width: 50%;
		padding-left: 15px;
	}
	
	.contact-block__title {
		margin: 0 0 43px;
		${Title} {
			font-size: ${({ theme }) => theme.fontSize.titleLg};
		}
	}
	
	@media (max-width: 767px) {
		padding-top: 40px;
		padding-bottom: 60px;
	}
	
	@media (max-width: 599px) {
		${Inner} {
			flex-direction: column-reverse;
			
			>* {
				width: 100%;
				padding: 0;
			}	
		}
		
		.map-container {
			margin-top: 40px;
			//margin-left: -20px;
			//margin-right: -20px;
		}
	}
`;

export default ContactBlock;
