import React  from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { decodeHTML } from 'entities';

const MetaData = ({ title, description, images }) => {

	let { options } = useStaticQuery(graphql`
		{
	  options: wordpressAcfOptions {
		options {
		  seo_title
		  seo_description
		  seo_separator
		  seo_images {
			image {
			  source_url
			}
		  }
		}
	  }
	}
	`);

	if(!options.options) return null;

	let { options: {
		seo_title,
		seo_description,
		seo_separator,
		seo_images,
	} } = options;

	title = title ? `${title} ${seo_separator} ${seo_title}` : seo_title;
	description = description ? description : seo_description;
	images = images ? images : seo_images;

	title = decodeHTML(title);
	description = decodeHTML(description);

	return(
		<Helmet
			title={title}
		>
			{ title ? (
				<meta property="og:title" content={title} />
			) : null }

			{ title ? (
				<meta property="twitter:title" content={title} />
			) : null }

			{ description ? (
				<meta name="description" content={description} />
			) : null }

			{ description ? (
				<meta name="twitter:description" content={description} />
			) : null }

			{ description ? (
				<meta property="og:description" content={description} />
			) : null }

			{ images && images.length ? images.map(img => {
				if(!img.image) return null;

				let src = img.image && img.image.source_url ? img.image.source_url : img.image.sizes ? img.image.sizes.large.source_url : null;

				if(!src) return null;

				return(
					<meta name="image" content={src} key={src} />
				);
			}) : null }

			{ images && images.length ? images.map(img => {
				if(!img.image) return null;

				let src = img.image && img.image.source_url ? img.image.source_url : img.image.sizes ? img.image.sizes.large.source_url : null;

				if(!src) return null;

				return(
					<meta name="og:image" content={src} key={src} />
				);
			}) : null }

			{ images && images.length ? images.map(img => {
				if(!img.image) return null;

				let src = img.image && img.image.source_url ? img.image.source_url : img.image.sizes ? img.image.sizes.large.source_url : null;

				if(!src) return null;

				return(
					<meta name="twitter:image" content={src} key={src} />
				);
			}) : null }

			<meta name="twitter:card" content="summary_large_image" />
		</Helmet>
	);

}

export default MetaData;