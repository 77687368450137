import styled from 'styled-components';

const Title = styled.h1`
	margin-bottom: ${({ sectionTitle }) => sectionTitle ? '50px' : 0};
	color: ${({ theme, white }) => white ? theme.color.white : theme.color.text};
	font-family: ${({ theme }) => theme.fontFamily.sansHeavy};
	font-size: ${({ theme, size }) => theme.fontSize[size]};
`;

Title.defaultProps = {
	size: 'titleMd',
}

export default Title;